import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  Divider,
  Icon,
} from '@makeably/creativex-design-system';
import styles from './Accordion.module.css';

const accordionPropTypes = {
  header: PropTypes.node.isRequired,
  chevronOnRight: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
  initiallyExpanded: PropTypes.bool,
  onClick: PropTypes.func,
};

const accordionDefaultProps = {
  chevronOnRight: true,
  children: null,
  disabled: false,
  divider: true,
  initiallyExpanded: true,
  onClick: () => {},
};

function Accordion({
  children,
  header,
  initiallyExpanded,
  onClick,
  disabled,
  divider,
  chevronOnRight,
}) {
  const [expanded, setExpanded] = useState(initiallyExpanded);
  const [animating, setAnimating] = useState(false);

  const iconType = expanded ? 'chevronDown' : 'chevronRight';
  const ariaControlsTargetId = crypto.randomUUID();

  const contentClasses = classnames({
    [styles.hidden]: !animating && !expanded,
    [styles.animateShow]: animating && expanded,
    [styles.animateHide]: animating && !expanded,
  });

  return (
    <>
      <button
        aria-controls={ariaControlsTargetId}
        aria-expanded={expanded}
        className={styles.header}
        disabled={disabled}
        type="button"
        onClick={() => {
          setAnimating(true);
          setExpanded(!expanded);
          onClick();
        }}
      >
        <div className={`u-flexRow ${chevronOnRight ? 'u-justifySpaceBetween' : ''}`}>
          { header }
          { !disabled && (
            <div className={styles.icon}>
              <Icon name={iconType} />
            </div>
          ) }
        </div>
      </button>
      { !expanded && divider && <Divider margin /> }
      <div
        className={contentClasses}
        id={ariaControlsTargetId}
        onAnimationEnd={() => setAnimating(false)}
      >
        { children }
      </div>
    </>
  );
}

Accordion.propTypes = accordionPropTypes;
Accordion.defaultProps = accordionDefaultProps;

export default Accordion;
