import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@makeably/creativex-design-system';
import BenchmarkMetrics from 'components/benchmarks/BenchmarkMetrics';
import BenchmarksFilter from 'components/benchmarks/BenchmarksFilter';
import CategoryVisualization from 'components/benchmarks/CategoryVisualization';
import {
  dateOptionStorageKey,
  filterStorageKey,
  getCategoryOptions,
  getMetricTotals,
} from 'components/benchmarks/utilities';
import { addToast } from 'components/organisms/Toasts';
import ReportTags from 'components/reporting/ReportTags';
import { findObjectByValue } from 'utilities/array';
import { getObjFilterTest } from 'utilities/filtering';
import {
  track,
  useViewPage,
} from 'utilities/mixpanel';
import { removeProperty } from 'utilities/object';
import { get } from 'utilities/requests';
import {
  companyDataBenchmarksCategoryReportsPath,
  dataBenchmarksCategoryReportsPath,
} from 'utilities/routes';
import {
  retrieveFromLocalStorage,
  saveToLocalStorage,
} from 'utilities/storage';
import './CategoryOverview.module.css';

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const propTypes = {
  categoryOptionsForCompany: PropTypes.arrayOf(optionProps).isRequired,
  channelLabelMap: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.string.isRequired,
  dateOptions: PropTypes.arrayOf(optionProps).isRequired,
  defaultDateOption: optionProps.isRequired,
  primaryCategory: PropTypes.string.isRequired,
};

const segments = [
  {
    label: 'Asset\nType',
    value: 'assetType',
  },
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'Market',
    value: 'market',
  },
];

async function getReports(dateOption) {
  const params = {
    date_option: dateOption.value,
  };
  const url = dataBenchmarksCategoryReportsPath(params);

  return get(url);
}

async function getMetrics(dateOption, filters, category) {
  const params = {
    asset_type: filters.assetType?.map(({ value }) => value),
    category: category.value,
    channel: filters.channel?.map(({ value }) => value),
    date_option: dateOption.value,
    market: filters.market?.map(({ value }) => value),
  };
  const url = companyDataBenchmarksCategoryReportsPath(params);

  return get(url);
}

function CategoryOverview({
  categoryOptionsForCompany,
  channelLabelMap,
  company,
  dateOptions,
  defaultDateOption,
  primaryCategory,
}) {
  const [reports, setReports] = useState([]);
  const [loadingReports, setLoadingReports] = useState(false);
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const initialFilters = retrieveFromLocalStorage(filterStorageKey) || {};
  const [currentFilters, setCurrentFilters] = useState(initialFilters);
  const [filteredReports, setFilteredReports] = useState(reports);
  const initialDateOption = retrieveFromLocalStorage(dateOptionStorageKey) || defaultDateOption;
  const [selectedDateOption, setSelectedDateOption] = useState(initialDateOption);
  const initialCategoryOptions = getCategoryOptions(filteredReports, categoryOptionsForCompany);
  const [categoryOptions, setCategoryOptions] = useState(initialCategoryOptions);
  const initialCategory = findObjectByValue(categoryOptions, primaryCategory);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [reportsForCategory, setReportsForCategory] = useState([]);
  const [companyMetrics, setCompanyMetrics] = useState({});
  const [reportMetrics, setReportMetrics] = useState(getMetricTotals(reportsForCategory));

  useViewPage();

  const loading = loadingReports || loadingMetrics;

  const fetchReports = async () => {
    setLoadingReports(true);
    const response = await getReports(selectedDateOption);

    if (response.isError) {
      const isTimeout = response.status === 504;
      const message = isTimeout ? 'The data request has timed out' : 'The data could not be loaded';

      addToast(message, { type: 'error' });
      setReports([]);
    } else {
      setReports(response.data);
    }
    setLoadingReports(false);
  };

  const fetchMetrics = async () => {
    setLoadingMetrics(true);
    const response = await getMetrics(selectedDateOption, currentFilters, selectedCategory);

    if (response.isError) {
      const isTimeout = response.status === 504;
      const message = isTimeout ? 'The data request has timed out' : 'The data could not be loaded';

      addToast(message, { type: 'error' });
      setCompanyMetrics({});
    } else {
      setCompanyMetrics(response.data);
    }
    setLoadingMetrics(false);
  };

  useEffect(() => {
    saveToLocalStorage(selectedDateOption, dateOptionStorageKey);
    track('apply_date_change', { date: selectedDateOption.label });

    fetchReports();
  }, [selectedDateOption]);

  useEffect(() => {
    saveToLocalStorage(currentFilters, filterStorageKey);
    track('apply_filters', { filters: currentFilters });
  }, [currentFilters]);

  useEffect(() => {
    track('apply_category', { category: selectedCategory.label });
  }, [selectedCategory]);

  useEffect(() => {
    const filterTest = getObjFilterTest(currentFilters);
    setFilteredReports(reports.filter(filterTest));
  }, [reports, currentFilters]);

  useEffect(() => {
    fetchMetrics();
  }, [selectedDateOption, currentFilters, selectedCategory]);

  useEffect(() => {
    const newCategoryOptions = getCategoryOptions(filteredReports, categoryOptionsForCompany);

    if (!findObjectByValue(newCategoryOptions)) {
      setSelectedCategory(initialCategory);
    }
    setCategoryOptions(newCategoryOptions);
  }, [filteredReports]);

  useEffect(() => {
    setReportsForCategory(filteredReports.filter((report) => (
      report.category === selectedCategory?.value
    )));
  }, [filteredReports, selectedCategory]);

  useEffect(() => {
    setReportMetrics(getMetricTotals(reportsForCategory));
  }, [reportsForCategory]);

  const removeCurrentFilter = (key) => {
    setCurrentFilters((last) => removeProperty(last, key));
  };

  return (
    <div className="u-flexColumn u-gap-16">
      <div className="u-flexRow u-justifyBetween">
        <div className="u-flexRow u-gap-8">
          <BenchmarksFilter
            channelLabelMap={channelLabelMap}
            isOpen={filterOpen}
            reports={reports}
            segments={segments}
            selections={currentFilters}
            onClose={() => setFilterOpen(false)}
            onOpen={() => setFilterOpen(true)}
            onSelectionsChange={setCurrentFilters}
          />
          <ReportTags
            filters={currentFilters}
            removeFilter={removeCurrentFilter}
            segments={segments}
            onFilterClick={() => setFilterOpen(true)}
          />
        </div>
        <Dropdown
          disabled={loading}
          menuProps={{ size: 'small' }}
          options={dateOptions}
          selected={selectedDateOption}
          size="small"
          onChange={(value) => setSelectedDateOption(value)}
        />
      </div>
      <BenchmarkMetrics
        loading={loadingReports}
        reports={filteredReports}
        type="category"
      />
      <Dropdown
        disabled={loading}
        label="Category"
        menuProps={{ size: 'medium' }}
        options={categoryOptions}
        selected={selectedCategory}
        size="medium"
        onChange={(value) => setSelectedCategory(value)}
      />
      <CategoryVisualization
        category={selectedCategory?.label}
        company={company}
        companyMetrics={companyMetrics}
        loading={loading}
        reportMetrics={reportMetrics}
      />
    </div>
  );
}

CategoryOverview.propTypes = propTypes;

export default CategoryOverview;
